import tw, { styled } from 'twin.macro'

// STYLED COMPONENTS

export const FlexContainer = styled.div`
  ${tw`w-screen h-screen flex flex-col content-start py-6 md:p-8`}
`

export const Container = styled.div`
  ${tw`max-w-screen-xl w-full mx-auto`}
`
