// @flow
import React from 'react'
import tw from 'twin.macro'

const Footer = ({ className }) => (
  <footer tw="mx-auto text-xs text-center py-4 lg:px-4" className={className}>
    <div>
      <p>
        © {new Date().getFullYear()} {` `}
        <a tw="underline" href="https://umbrellafamily.com">
          <span tw="whitespace-no-wrap">Umbrella Family and Child Centres of Hamilton</span>
        </a>
      </p>
    </div>
  </footer>
)

export default Footer
