/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import tw, { css } from 'twin.macro'

import Footer from './Footer'
import { FlexContainer } from './styles'

import Header from './Header'

const Layout = ({ hideSteps, stepType, children, ...props }) => (
  <FlexContainer {...props}>
    <Header />
    <main tw="flex-auto">{children}</main>
    <Footer />
  </FlexContainer>
)

export default Layout
