import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import Logo from '../images/umbrella-logo-horizontal.svg'

import useSiteMetadata from '../hooks/useSiteMetadata'
import { useAuth } from '../context/auth-provider'

const Header = ({ className }) => {
  const { user, logout } = useAuth()
  const { title } = useSiteMetadata()

  return (
    <div
      tw="md:flex text-center sm:text-right items-center justify-center md:justify-between w-full mb-6 lg:mb-8"
      className={className}
    >
      <a tw="inline-block w-2/3 sm:w-2/3 md:w-1/2 max-w-xs mb-4 md:m-0" href="https://umbrellafamily.com">
        <Logo title={title} />
      </a>

      {user && (
        <nav tw="flex space-x-4 text-sm">
          <span>Welcome {user?.displayName}</span>
          <button type="button" onClick={() => logout()}>
            Sign Out
          </button>
        </nav>
      )}
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  showTitle: PropTypes.bool,
}

// Header.defaultProps = {
//   siteTitle: ``,
//   pathname: `/`,
// }

export default Header
